import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Films from './pages/Films';
import Photographs from './pages/Photographs';
import IndividualFilms from './pages/IndividualFilms';
import IndividualPhotos from './pages/IndividualPhotos';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/filmes" element={<Films />} />
      <Route path="/fotos" element={<Photographs />} />
      <Route path="/filmes/:filmeId" element={<IndividualFilms />} />
      <Route path="/fotos/:fotoId" element={<IndividualPhotos />} />
      <Route path="/contato" element={<Contact />} />
      <Route path ="/sobre" element={<About />}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
