import { NavLink } from 'react-router-dom';
import { useState } from 'react';

import Logo3R from '../../assets/images/logo.png';
import InstagramIcon from '../../assets/images/instagram-icon.svg';
import FacebookIcon from '../../assets/images/facebook-icon.svg';

import './styles.css';

type Props = {
    type: string;
}

function Header({ type } : Props) {

    const [mobileToggle, setMobileToggle] = useState(false);
    const [color, setColor] = useState(false);

    const changeColor = () => {
        window.scrollY >= 150 ? setColor(true) : setColor(false);
    }
 
    window.addEventListener("scroll", changeColor);

    return(
        <header className={color || type === 'black' ? 'header-bg header-nav' : 'header-transparent header-nav'}>
            <div className="container">
                <NavLink to="/">
                    <div className="header-logo">
                        <img src={Logo3R} alt="Logo 3rvideo" />
                    </div>
                </NavLink>

                <div className={mobileToggle ? "menu-section on" : "menu-section"} onClick={() => setMobileToggle(!mobileToggle)}>
                    <div className="menu-toggle">
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                    </div>

                    <nav>
                        <ul>
                            <li>
                                <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''} target="_self">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/fotos" className={({ isActive }) => isActive ? 'active' : ''} target="_self">Fotografias</NavLink>
                            </li>
                            <li>
                                <NavLink to="/filmes" className={({ isActive }) => isActive ? 'active' : ''} target="_self">Filmes</NavLink>
                            </li>
                            <li>
                                <NavLink to="/sobre" className={({ isActive }) => isActive ? 'active' : ''} target="_self">Sobre</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contato" className={({ isActive }) => isActive ? 'active' : ''} target="_self">Contato</NavLink>
                            </li>
                        </ul>

                        <div className="social-media">
                            <NavLink to="https://instagram.com/3rvideo" target='__blank'>
                                <img src={InstagramIcon} alt="Instagram" />
                            </NavLink>

                            <NavLink to="https://www.facebook.com/3rvideofoto" target='__blank'>
                                <img src={FacebookIcon} alt="Instagram" />
                            </NavLink>
                        </div>
                    </nav>


                </div>
            </div>
        </header>
    )
}

export default Header;