import Header from '../../components/Header';
import ButtonWhatsappChat from '../../components/ButtonWhatsappChat';
import Title from '../../components/Title';
import Footer from '../../components/Footer';

import './styles.css';

function About(){
    return(
        <>
            <Header type="black" />
            <ButtonWhatsappChat />

            <main className="about">
                <div className="container">
                    <div className="m-top110">
                        <Title text="Sobre" />

                        <div className="about-subtitle">
                            <div>
                                <h2>3rvideofoto</h2>
                            </div>
                            <p>Vivo todos os dias a fotografia</p>
                        </div>

                        <div className="about-text">
                            <p>Olá, muito prazer. Seja bem-vindo(a).</p>
                            <p>Nós da <strong>3rvideofoto</strong> trabalhamos há 17 anos construindo sonhos de forma única. Para nós, cada momento tem uma essência diferente, 
                                por isso nos dedicamos ao máximo para proporcionar a você belas imagens e registrar o seu momento especial.</p>
                            <p>Confira nosso portfólio e vamos conversar?</p>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default About;