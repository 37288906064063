import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { allPhotos } from '../../data/photos/allPhotos';
import { weddingPhotos } from '../../data/photos/weddingPhotos';
import { fifteenPhotos } from '../../data/photos/fifteenPhotos';
import { essayPhotos } from '../../data/photos/essayPhotos';
import { makingOfPhotos } from '../../data/photos/makingOfPhotos';
import { childrenAnniversaryPhotos } from '../../data/photos/childrenAnniversaryPhotos';
import { anniversaryPhotos } from '../../data/photos/anniversaryPhotos';

import Card from '../Card';

import './styles.css';

function PhotoFilter(){

    const [toggleCategory, setToggleCategory] = useState(1);  
    const [visibleCards, setVisibleCards] = useState(6);
    const [showMoreButton, setShowMoreButton] = useState(true);

    // useEffect para associar a categoria de fotos à respectiva lista para tratativas de mostrar o botão "Ver mais" de cada categoria dinamicamente
    useEffect(() => {
        
        const objToggleCategoryPhotos = {
            1: allPhotos,
            2: weddingPhotos,
            3: fifteenPhotos,
            4: essayPhotos,
            5: makingOfPhotos,
            6: childrenAnniversaryPhotos,
            7: anniversaryPhotos
        } as any

        let lstPhotos = objToggleCategoryPhotos[toggleCategory] || -1;

        visibleCards >= lstPhotos.length ? setShowMoreButton(false) : setShowMoreButton(true);
    }, [toggleCategory, visibleCards])

    const showMoreItems = () => {
        setVisibleCards((prevValue) => prevValue + 6);
    }

    const defineToggleTab = (index : number) => {
        setToggleCategory(index);        
        setShowMoreButton(true);
        defineInitialVisibleItems();
    }

    const defineInitialVisibleItems = () => {
        setVisibleCards(6);
    }

    return(
        <>
            <div className="filter">
                <span className="line"></span>
                    <nav className="filter-content">
                        <p className={toggleCategory === 1 ? "active--tab" : ""} onClick={() => defineToggleTab(1)}>Todos</p>
                        <p className={toggleCategory === 2 ? "active--tab" : ""} onClick={() => defineToggleTab(2)}>Casamentos</p>
                        <p className={toggleCategory === 3 ? "active--tab" : ""} onClick={() => defineToggleTab(3)}>15 anos</p>
                        <p className={toggleCategory === 4 ? "active--tab" : ""} onClick={() => defineToggleTab(4)}>Ensaios</p>
                        <p className={toggleCategory === 5 ? "active--tab" : ""} onClick={() => defineToggleTab(5)}>Making of</p>
                        <p className={toggleCategory === 6 ? "active--tab" : ""} onClick={() => defineToggleTab(6)}>Infantis</p>
                        <p className={toggleCategory === 7 ? "active--tab" : ""} onClick={() => defineToggleTab(7)}>Aniversários</p>
                    </nav>
                <span className="line"></span>
            </div>

            <section>
                <div className={toggleCategory === 1 ? "cards-wrapper" : "d-none"}>
                    {allPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>
                
                <div className={toggleCategory === 2 ? "cards-wrapper" : "d-none"}>
                    {weddingPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 3 ? "cards-wrapper" : "d-none"}>
                    {fifteenPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 4 ? "cards-wrapper" : "d-none"}>    
                    {essayPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 5 ? "cards-wrapper" : "d-none"}>
                    {makingOfPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 6 ? "cards-wrapper" : "d-none"}>
                    {childrenAnniversaryPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 7 ? "cards-wrapper" : "d-none"}>
                    {anniversaryPhotos.slice(0, visibleCards).map((photo, index) => (
                        <div key={index}>
                            <Link to={`/fotos/${photo.customUrl}`}>
                                <Card item={photo} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="btn-show-more-wrapper">
                    {showMoreButton && (
                        <button onClick={showMoreItems} className="btn">Ver mais</button>
                    )}
                </div>
            </section>
        </>
        
    )
}

export default PhotoFilter;