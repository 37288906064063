import './styles.css';

type Props = {
    text: string;
}

function Title({ text } : Props ) {
    return(
        <div className="title">
            <h2>{text}</h2>
        </div>
    )
}

export default Title;