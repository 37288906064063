import { PortfolioCardInfo } from '../../types/PortfolioCardInfo';
import './styles.css';

type Props = {
    item: PortfolioCardInfo;
}

function Card({ item } : Props){
    return(
        <article className="card">
            <header>
                <img src={item.thumbnail} alt={item.name} />
            </header>
            <div>
                <p>{item.name}</p>
                <p>{item.type}</p>
            </div>
        </article>
    )
}

export default Card;