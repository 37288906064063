import { NavLink } from "react-router-dom";
import { feedback } from "../../data/feedback";

import Header from "../../components/Header";
import ButtonWhatsappChat from "../../components/ButtonWhatsappChat";
import HomeCarousel from "../../components/HomeCarousel";
import Title from "../../components/Title";
import FeedbackSlider from "../../components/FeedbackSlider";
import Footer from "../../components/Footer";

import Img1 from '../../assets/images/home/trabalhos-img1.jpg';
import Img2 from '../../assets/images/home/trabalhos-img2.jpg';
import Img3 from '../../assets/images/home/trabalhos-img3.jpg';
import Img5 from '../../assets/images/home/trabalhos-img5.jpg';
import Img6 from '../../assets/images/home/trabalhos-img6.jpg';
import Img7 from '../../assets/images/home/trabalhos-img7.jpg';
import Img8 from '../../assets/images/home/trabalhos-img8.jpg';

import './styles.css';

function Home() { 

    const directUrl = (url : string) => {
        window.location.replace(url);
    }


    return (
        <>
            <Header type="transparent" />
            <HomeCarousel />
            <ButtonWhatsappChat />

            {/* EFEITO SUAVE NO HOVER + FOTO VERTICAL N TÁ PEGANDO LINK + QUANDO PASSA HOVER EM CIMA DO TEXTO DA FOTO, O FUNDO DA FOTO TÁ SAINDO O FILTER BRIGHTNESS */ }

            <main>
                <section>
                    <div className="container">
                        <Title text="Trabalhos" />

                        <div className="collage-photos-grid">
                            <div className="photo photo1">
                                <NavLink to="/fotos/casamento_juliana_guilherme">
                                    <img src={Img1} alt="Casamento Juliana & Guilherme" />
                                    <div className="collage-photos-info">
                                        <h3>Juliana & Guilherme</h3>
                                        <p>Casamento</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo2">
                                <NavLink to="/fotos/making_of_mary">
                                    <img src={Img2} alt="Making of Mary" />
                                    <div className="collage-photos-info">
                                        <h3>Mary</h3>
                                        <p>Making of</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo3">
                                <NavLink to="/fotos/15_anos_ana_beatriz_2">
                                    <img src={Img3} alt="15 anos Ana Beatriz" />
                                    <div className="collage-photos-info">
                                        <h3>Ana Beatriz</h3>
                                        <p>15 anos</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo4" onClick={e=> directUrl('/fotos/ensaio_islayne_alex')}>
                                <div className="collage-photos-info">
                                    <h3>Islayne & Alex</h3>
                                    <p>Pré-Wedding</p>
                                </div>
                            </div>
                            <div className="photo photo5">
                                <NavLink to="/fotos/making_of_monique">
                                    <img src={Img5} alt="Making of Monique & Mário" />
                                    <div className="collage-photos-info">
                                        <h3>Monique & Mário</h3>
                                        <p>Making of</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo6">
                                <NavLink to="/fotos/ensaio_duda">
                                    <img src={Img6} alt="Ensaio Duda" />
                                    <div className="collage-photos-info">
                                        <h3>Duda</h3>
                                        <p>Ensaio</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo7">
                                <NavLink to="/fotos/infantil_alice">
                                    <img src={Img7} alt="Infantil Alice" />
                                    <div className="collage-photos-info">
                                        <h3>Alice</h3>
                                        <p>Infantil</p>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="photo photo8">
                                <NavLink to="/fotos/ensaio_laila_marcelo">
                                    <img src={Img8} alt="Pré-Wedding Leila & Marcelo" />
                                    <div className="collage-photos-info">
                                        <h3>Laila & Marcelo</h3>
                                        <p>Pré-Wedding</p>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="feedback">
                    <div className="container">
                        <Title text="O que falam sobre nós?" />
                        <FeedbackSlider slides={feedback} />
                    </div>
                </section>

                <section className="awards">
                    <div className="container">
                        <Title text="Premiações" />
                        <div className="awards-content">
                            <div id="wp-rated">
                                <NavLink to="https://www.casamentos.com.br/fotografo-casamento/3rvideofoto--e191512" target="_blank" rel="nofollow" title="Recomendado em www.casamentos.com.br">
                                    <img width="125" height="125" alt="Recomendado em Casamentos.com.br" id="wp-rated-img" src="https://cdn1.casamentos.com.br/assets/img/badges/rated/badge-rated-10.png"/>
                                </NavLink>
                            </div>
                            <div id="wp-ratedWA">
                                <NavLink to="https://www.casamentos.com.br/fotografo-casamento/3rvideofoto--e191512" target="_blank" rel="nofollow" title="Recomendado em www.casamentos.com.br">
                                    <img width="125" height="125" alt="Recomendado em Casamentos.com.br" id="wp-rated-img" src="https://ik.imagekit.io/zqxyh6u3ylz/3rvideo/Home%20-%20Feedback/selo-recomendado-2017_j2obGO7Du.jpg?updatedAt=1725127676702"/>
                                </NavLink>
                            </div>
                            <div id="wp-ratedWA">
                                <NavLink to="https://www.casamentos.com.br/fotografo-casamento/3rvideofoto--e191512" target="_blank"  rel="nofollow" title="3rvideofoto, ganhador Casamentos Awards 2021 de casamentos.com.br">
                                    <img width="125" height="125" alt="3rvideofoto, ganhador Casamentos Awards 2021 de Casamentos.com.br" id="wp-ratedWA-img-2021" src="https://cdn1.casamentos.com.br/img/badges/2021/badge-weddingawards_pt_BR.jpg" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default Home;