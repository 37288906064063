import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { allFilms } from '../../data/films/allFilms';
import { weddingFilms } from '../../data/films/weddingFilms';
import { fifteenFilms } from '../../data/films/fifteenFilms';
import { childrenAnniversaryFilms } from '../../data/films/childrenAnniversaryFilms';
import { anniversaryFilms } from '../../data/films/anniversaryFilms';

import Card from '../Card';

import './styles.css';

function FilmFilter(){

    const [toggleCategory, setToggleCategory] = useState(1);  
    const [visibleCards, setVisibleCards] = useState(6);
    const [showMoreButton, setShowMoreButton] = useState(true);

    // useEffect para associar a categoria de filmes à respectiva lista para tratativas de mostrar o botão "Ver mais" de cada categoria dinamicamente
    useEffect(() => {
        
        const objToggleCategoryFilms = {
            1: allFilms,
            2: weddingFilms,
            3: fifteenFilms,
            4: childrenAnniversaryFilms,
            5: anniversaryFilms
        } as any

        let lstFilms = objToggleCategoryFilms[toggleCategory] || -1;

        visibleCards >= lstFilms.length ? setShowMoreButton(false) : setShowMoreButton(true);
    }, [toggleCategory, visibleCards])

    const showMoreItems = () => {
        setVisibleCards((prevValue) => prevValue + 6);
    }

    const defineToggleTab = (index : number) => {
        setToggleCategory(index);        
        setShowMoreButton(true);
        defineInitialVisibleItems();
    }

    const defineInitialVisibleItems = () => {
        setVisibleCards(6);
    }

    return(
        <>
            <div className="filter">
                <span className="line"></span>
                    <nav className="filter-content">
                        <p className={toggleCategory === 1 ? "active--tab" : ""} onClick={() => defineToggleTab(1)}>Todos</p>
                        <p className={toggleCategory === 2 ? "active--tab" : ""} onClick={() => defineToggleTab(2)}>Casamentos</p>
                        <p className={toggleCategory === 3 ? "active--tab" : ""} onClick={() => defineToggleTab(3)}>15 anos</p>
                        <p className={toggleCategory === 4 ? "active--tab" : ""} onClick={() => defineToggleTab(4)}>Infantis</p>
                        <p className={toggleCategory === 5 ? "active--tab" : ""} onClick={() => defineToggleTab(5)}>Aniversários</p>
                    </nav>
                <span className="line"></span>
            </div>

            <section>
                <div className={toggleCategory === 1 ? "cards-wrapper" : "d-none"}>
                    {allFilms.slice(0, visibleCards).map((film, index) => (
                        <div key={index}>
                            <Link to={`/filmes/${film.customUrl}`}>
                                <Card item={film} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 2 ? "cards-wrapper" : "d-none"}>
                    {weddingFilms.slice(0, visibleCards).map((film, index) => (
                        <div key={index}>
                            <Link to={`/filmes/${film.customUrl}`}>
                                <Card item={film} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 3 ? "cards-wrapper" : "d-none"}>
                    {fifteenFilms.slice(0, visibleCards).map((film, index) => (
                        <div key={index}>
                            <Link to={`/filmes/${film.customUrl}`}>
                                <Card item={film} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 4 ? "cards-wrapper" : "d-none"}>
                    {childrenAnniversaryFilms.slice(0, visibleCards).map((film, index) => (
                        <div key={index}>
                            <Link to={`/filmes/${film.customUrl}`}>
                                <Card item={film} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className={toggleCategory === 5 ? "cards-wrapper" : "d-none"}>
                    {anniversaryFilms.slice(0, visibleCards).map((film, index) => (
                        <div key={index}>
                            <Link to={`/filmes/${film.customUrl}`}>
                                <Card item={film} />
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="btn-show-more-wrapper">
                    {showMoreButton && (
                        <button onClick={showMoreItems} className="btn">Ver mais</button>
                    )}
                </div>
            </section>
        </>
    )
}

export default FilmFilter;