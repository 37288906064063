export const allFilms = [
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme1.jpg?updatedAt=1726521828708',
        name: 'Stephanie & Matheus',
        type: 'Casamento',
        typeUrl: 'Casamento',
        location: 'Dimatonni - Itaboraí',
        date: '14 de junho de 2021',
        description: 'Foi uma grande surpresa poder registrar o casamento da Stephanie e do Matheus, um casal maravilhoso que conheci pela internet só nos encontramos pessoalmente no dia do casamento. Tinha certeza que seria um dia cheio de emoções, foi tudo do jeito que eu imaginava, com momentos que ficarão guardados no coração de cada convidado. Felicidade ao lindo casal.',
        videoSrc: 'https://player.vimeo.com/video/562916126?h=a1dba8f0ba',
        customUrl: 'casamento_stephanie_matheus'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme2.jpg?updatedAt=1728073029311',
        name: 'Juliana & Guilherme',
        type: 'Casamento',
        typeUrl: 'Casamento',
        location: '',
        date: '14 de junho de 2021',
        description: 'Conheci a Juliana quando ela tinha 15 anos e de lá para venho registrando os momentos da sua família, tinha certeza que as imagens do casamento seriam inesquecíveis, esse casal é sensacional.',
        videoSrc: 'https://player.vimeo.com/video/561862089?h=ab35009ffd',
        customUrl: 'casamento_juliana_guilherme'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme3.jpg?updatedAt=1726346999912',
        name: 'Carolina & Caio',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Niterói',
        date: '17 de setembro de 2020',
        description: 'A festinha da Carolina e do Caio foi durante o dia em um local muito bonito, com natureza ao redor e uma bela paisagem. Adorei registrar esse momento especial na vida dos dois.',
        videoSrc: 'https://player.vimeo.com/video/458976026?h=bcd576da10',
        customUrl: 'infantil_carolina_caio'

    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme4.jpg?updatedAt=1728073189760',
        name: 'Juliana & Lohran',
        type: 'Casamento',
        typeUrl: 'Casamento',
        location: 'La Casa de Vidro',
        date: '25 de janeiro de 2020',
        description: 'O casamento da Juliana e do Lohran foi fantástico, uma festa cheia de energia e animação! Registrar esses momentos foi muito gratificante para mim e para a minha equipe. Casal nota 10.',
        videoSrc: 'https://player.vimeo.com/video/437216124?h=f59ce66942',
        customUrl: 'casamento_juliana_lohran'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme5.jpg?updatedAt=1726347000207',
        name: 'Lavínia & Letizia',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: '',
        date: '09 de agosto de 2019',
        description: 'Já é o segundo aniversário das meninas que registramos. Elas são incríveis, no início ficam quietinhas e depois são energia pura, brincam e dançam o tempo todo. Fico feliz em poder participar desse momento especial na vida das duas e da família.',
        videoSrc: 'https://player.vimeo.com/video/386833524?h=f4153e9dbd',
        customUrl: 'infantil_lavinia_letizia'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme6.jpg?updatedAt=1726346999875',
        name: 'Betina',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Niterói',
        date: '19 de julho de 2019',
        description: 'A Betina completou 1 ano de vida, sua festa teve muita diversão. Uma bandinha tocou música ao vivo e ficou tudo maravilhoso. Em um ambiente agradável, pude registrar belas imagens.',
        videoSrc: 'https://player.vimeo.com/video/349797250?h=010beb9a07',
        customUrl: 'infantil_betina'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme7.jpg?updatedAt=1726346999628',
        name: 'Bernardo',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Niterói',
        date: '13 de julho de 2019',
        description: 'A festinha do Bernardo foi como se eu estivesse em uma corrida de Fórmula 1, a casa de festas tinha 3 andares. O Bernardo é um garoto cheio de energia e brincava o tempo todo. Registrei muitos momentos divertidos dele.',
        videoSrc: 'https://player.vimeo.com/video/349794948?h=fcdf7210c3',
        customUrl: 'infantil_bernardo'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme8.jpg?updatedAt=1726346999369',
        name: 'Maju',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Niterói',
        date: '17 de março de 2018',
        description: 'A festa da Maju, uma menininha linda cheia de alegria, foi sensacional com muitas brincadeiras, foi um prazer contar um pouquinho dessa história em vídeo.',
        videoSrc: 'https://player.vimeo.com/video/275131664?h=b7f4680483',
        customUrl: 'infantil_maju'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme9.jpg?updatedAt=1726346999788',
        name: 'Maria Victoria',
        type: '15 anos',
        typeUrl: '15',
        location: '',
        date: '14 de junho de 2018',
        description: 'O aniversário da Maria Victória foi muito legal, com dança e personagens que contribuíram para a imersão no tema da Alice no país das maravilhas. Fizemos belas imagens da aniversariante em seu momento especial.',
        videoSrc: 'https://player.vimeo.com/video/275129428?h=a77539b344',
        customUrl: '15_anos_maria_victoria'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme10.jpg?updatedAt=1726346999828',
        name: 'Kamylli & Sávio',
        type: 'Casamento',
        typeUrl: 'Casamento',
        location: 'Solar Imperial',
        date: '24 de março de 2018',
        description: '',
        videoSrc: 'https://player.vimeo.com/video/272215858?h=802c3d3850',
        customUrl: 'casamento_kamylli_savio'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme11.jpg?updatedAt=1726347000900',
        name: 'Bernardo',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Megga Festas Eventos',
        date: '12 de janeiro de 2018',
        description: '',
        videoSrc: 'https://player.vimeo.com/video/250781456?h=b502c0cd8c',
        customUrl: 'infantil_bernardo_2'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme12.jpg?updatedAt=1726347001056',
        name: 'Vanessa & Clarissa',
        type: '15 anos',
        typeUrl: '15',
        location: 'Clube Naval Charitas',
        date: '',
        description: '',
        videoSrc: 'https://player.vimeo.com/video/209834375?h=f5921458f2',
        customUrl: '15_anos_vanessa_clarissa'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme13.jpg?updatedAt=1726347001009',
        name: 'Iago',
        type: 'Aniversário',
        typeUrl: 'Aniversario',
        location: 'Solar Degração',
        description: 'Linda pool party do meu amigo Iago, foi um grande prazer escrever um pouquinho dessa história, em seus 21 anos.',
        videoSrc: 'https://player.vimeo.com/video/213453709?h=01ba02fe6a',
        customUrl: 'aniversario_iago'
    },
    {
        thumbnail: 'https://ik.imagekit.io/jxe5ouur3/Filmes/Cards/filme14.jpg?updatedAt=1726347000915',
        name: 'Lorenzo',
        type: 'Infantil',
        typeUrl: 'Infantil',
        location: 'Espaço Viva Eventos e Festas',
        date: '26 de agosto de 2016',
        description: '',
        videoSrc: 'https://player.vimeo.com/video/168207047?h=f68c2a542a',
        customUrl: 'infantil_lorenzo'
    },
]