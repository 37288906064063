import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { WPP_URL } from '../../utils/WhatsappLink';
import ContactForm from '../../components/ContactForm';
import Header from '../../components/Header';
import Title from '../../components/Title';

import WhatsappIcon from '../../assets/images/contact/whatsapp-icon-black.svg';
import InstagramIcon from '../../assets/images/contact/instagram-icon-black.svg';
import FacebookIcon from '../../assets/images/contact/facebook-icon-black.svg';

import './styles.css';

function Contact(){
    return(
        <>
            <Header type="black" />

            <main>
                <div className="container">
                    <div className="m-top110">
                        <Title text="Contato" />
                    </div>

                    <div className="contact-form-wrapper">
                        <p>Entre em contato para solicitar um orçamento</p>
                        <ContactForm />
                        

                        <div className="form-social-media">
                            <div>
                                <Link to="https://instagram.com/3rvideo" target="_blank">
                                    <img src={InstagramIcon} alt="Instagram" />
                                </Link>
                            </div>
                            <div>
                                <Link to="https://facebook.com/3rvideofoto" target="_blank">
                                    <img src={FacebookIcon} alt="Facebook" />
                                </Link>
                            </div>
                            <div>
                                <Link to={WPP_URL} target="_blank">
                                    <img src={WhatsappIcon} alt="Whatsapp" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <ToastContainer autoClose={5000} closeOnClick className="custom-toast" draggable theme="colored"/>
                </div>
            </main>
        </>
    )
}

export default Contact;