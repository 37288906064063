import { useParams } from 'react-router-dom';
import { allFilms } from '../../data/films/allFilms';

import Header from '../../components/Header';
import ButtonWhatsappChat from '../../components/ButtonWhatsappChat';
import Title from '../../components/Title';
import SecondaryCard from '../../components/SecondaryCard';
import Footer from '../../components/Footer';

import './styles.css';

type UrlParams = {
    filmeId: string;
}

function IndividualFilms() {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Hook para pegar o id de cada filme e passá-lo na URL
    const { filmeId } = useParams<UrlParams>();
    const result = allFilms.filter((film) => film.customUrl === filmeId);

    const url = window.location.href;
    const indexFilmes = url.indexOf('filmes/') + 7;
    const indexType = url.indexOf('_');

    const name = url.slice(indexType);
    const type = url.slice(indexFilmes, indexType);

    const typeName = type + name;
    const filteredFilms = allFilms.filter(film => film.typeUrl.toLowerCase().includes(type) && !film.customUrl.includes(typeName));

    let shuffled = filteredFilms.sort(() => 0.5 - Math.random());
    let selectedFilmsToShow = shuffled.slice(0, 3);

    const createTitleText = () => {
        const baseText = 'Veja também outros';

        const objTypeText = {
            "casamento": `${baseText} casamentos`,
            "infantil": `${baseText} infantis`,
            "15": `${baseText} 15 anos`,
            "aniversario": `${baseText} aniversários`
        } as any

        let resulTypeText = objTypeText[type] || -1;

        return selectedFilmsToShow.length > 0 ? resulTypeText : '';
    }
    
    return(
        <>
            <Header type="black" />
            <ButtonWhatsappChat />

            <main className="individual-film">
                <div className="container">
                    <div className="m-top110 d-flex-column-center">
                        {result.map((film, index) => (
                            <div key={index} className="individual-film-content">
                                <p className="name">{film.name}</p>
                                <div className="location-date">
                                    <p>{film.location}</p>
                                    {film.location !== '' && film.date !== '' ? <p>&#124;</p> : ''}
                                    <p>{film.date}</p>
                                </div>
                                <div className="description">
                                    <p>{film.description}</p>
                                </div>
                                <div className="video">
                                    <iframe title="vimeo-player" src={film.videoSrc}  frameBorder="0" allowFullScreen></iframe>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            {selectedFilmsToShow.length > 0 &&
                <section>
                    <div className="container-related-individual-media">
                        <Title text={createTitleText() as any} />
        
                        <div className="secondary-cards-container">
                            {selectedFilmsToShow.map((film, index) => (
                                <SecondaryCard film={film} key={index} />
                            ))}
                        </div>
                    </div>
                </section>
            }

            <Footer />
        </>
    )
}

export default IndividualFilms;