export const feedback = [
    {
        id: 1,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback1.jpg?updatedAt=1726520034505',
        description: 'O profissionalismo da equipe 3Rvideofoto é sem igual! Raphael é uma pessoa fantástica muito atenciosa, junto com sua equipe consegue capturar cada detalhe da festa, o que nos permite reviver nosso dia especial todas as vezes que pegamos o álbum de fotografia ou assistimos a filmagem. 3RvideoFoto foi responsável pelos meus 15 anos, e quando eu e meu marido decidimos casar já tínhamos certeza de quem seria nosso fotógrafo. Muito obrigada a todos os profissionais envolvidos em nosso casamento, obrigada por eternizarem tão perfeitamente nosso momento, vocês são nossa escolha número um.',
        people: 'Mariana & Gabriel'
    },
    {
        id: 2,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback2.jpg?updatedAt=1726521783234',
        description: 'Assisti na tv e só tenho que agradecer pelo trabalho de vocês! Ficou incrível! E esse pen drive na caixa de madeira é muito caprichoso! Amei a filmagem! Indico mil vezes! Obrigada por ter feito parte desse sonho e ter entregue algo com excelência!',
        people: 'Stephanie & Matheus'
    },
    {
        id: 3,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback3.jpg?updatedAt=1726520074391',
        description: 'A 3rvideo eu só tenho uma coisa a dizer “Perfeito, tudo muito perfeito e completo o trabalho de vocês, e mais uma vez eu digo que tudo tem que ser no tempos de Deus, onde decidi contratar sem medo a uma semana a do meu casamento, muito obrigado por toda dedicação e paciência e pelo desconto maravilhoso .. Não temos forças para agradecer por tudo que foi realizado, por toda cobertura de fotos e filmagens, queremos tudo logo rsrs ansiosos .. sonho agora sim realizado ❤️',
        people: 'Juliana & Lohran'
    },
    {
        id: 4,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback4.jpg?updatedAt=1726520104621',
        description: 'O Raphael e a 3Rvideo eternizaram o melhor dia da minha vida! Profissionais incríveis de uma atenção sem igual, que com a sua sensibilidade conseguiram captar todas as emoções e momentos únicos desse dia tão especial. Qualidade e profissionalismo! Excelente! Obrigada 3Rvideo por serem responsáveis por me dar a recordação mais especial que ja tive. Sem dúvidas a minha melhor escolha!',
        people: 'Juliana & Guilherme'
    },
    {
        id: 5,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback5.jpg?updatedAt=1726520132398',
        description: 'Ótimo profissional! Muito atencioso e experiente. Realmente realizei o sonho dos 15 anos da minha Mary (filha), que estarão eternamente guardados tanto no vídeo como nas fotos. Obrigado a toda equipe pelo carinho e o serviço de qualidade, recomendo a todos.',
        people: 'Mary'
    },
    {
        id: 6,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback6.jpg?updatedAt=1726520160418',
        description: 'Um profissional de excelência, nos tratou com muito carinho e paciência. Mas quando recebemos as fotos, o resultado final, fomos surpreendidos pela grandeza dos detalhes captados, a sensibilidade, a emoção registrada em cada momento. O dia mais feliz de nossas vidas foi belamente eternizado pelos teus cliques. Palavras não são suficientes para agradecer.',
        people: 'Raissa & Magno'
    },
    {
        id: 7,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback7.jpg?updatedAt=1726521310175',
        description: 'Incrível foi a palavra que mais ouvimos sobre o nosso vídeo de casamento. Fez um sucesso enorme entre nossos amigos e familiares. Nós amamos o resultado. Foi muito além do que esperávamos. Só temos a agradecer ao excelente atendimento e profissionalismo. Estamos encantados e não nos cansamos de ver o vídeo. obrigada!',
        people: 'Kamylli & Sávio'
    },
    {
        id: 8,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback8.jpg?updatedAt=1726520192170',
        description: 'Super recomendo! Excelente profissional, atencioso e carinhoso. Amei todas as minhas fotos e meu vídeo de casamento. Estamos totalmente satisfeitos e encantados com o resultado! Não pensem, podem contratar!',
        people: 'Camila & Clayton'
    }, 
    {
        id: 9, 
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback9.jpg?updatedAt=1726520234170',
        description: 'O Raphael é muito atencioso e paciente. Faz tudo do jeito que o cliente deseja e está sempre disponível. Foi uma experiência maravilhosa na nossa vida e ele soube registrar isso perfeitamente. Trabalho de muita qualidade!',
        people: 'Rafaela & Antoniele'
    },
    {
        id: 10,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback10.jpg?updatedAt=1726521129602',
        description: 'Adoramos o vídeo do aniversário da nossa Maria Julia! Ficou incrível! Como foi bom relembrar a festa de um ano da nossa pequena, um dia inesquecível e de muita felicidade. Graças  ao profissionalismo e talento do Raphael, essas imagens estarão para sempre guardadas para maternos a saudade deste dia! Com certeza em uma próxima oportunidade, estaremos fazendo essa parceria novamente. Obrigada, Raphael!',
        people: 'Maju'
    },
    {
        id: 11,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback11.jpg?updatedAt=1726521980583',
        description: 'Acredito que o Raphael é o tipo de profissional que nem precisa de indicações é só  olhar o trabalho dele para se apaixonar.... dentre tantas escolhas que eu fiz para a realização de um sonho, sem dúvidas a melhor foi escolher um bom profissional para registrar o momento que ficará para sempre em minha memória. Parabéns Raphael pelo seu grande trabalho, pela sua equipe maravilhosa, tornou meu sonho e o da minha filha em realidade,  sem vocês nada disso poderia acontecer,  só tenho a agradecer pela dedicação e carinho que teve com a minha filha.',
        people: 'Maria Victoria'
    },
    {
        id: 12,
        imgSrc: 'https://ik.imagekit.io/jxe5ouur3/Home/Feedback/feedback12.jpg?updatedAt=1726522152228',
        description: 'Fotos tem o poder de eternizar momentos. Garantir que gerações e gerações conhecerão e viverão aquele mesmo instante que pra gente foi real. Por isso que eu tiro muitas fotos e faço muuuitos álbuns! Quero Lembranças para sempre. Quero poder ver e reviver todos esses dias a qualquer momento!! A filmagem foi algo novo para mim! Minha sogra sugeriu que era algo importante como lembrança desse dia especial e através da minha cunhada cheguei no maravilhoso @3rvideo! Rafa, você conseguiu me emocionar, me fazer reviver e sentir novamente tudo que senti nesse dia tão especial para mim e para toda família!! Muito obrigada e vamos que vamos que teremos muitas filmagens pela frente.#betinafaz1 #betinalinda',
        people: 'Paula Gorenstein'
    }
]