import { useState } from "react";
import { Feedback } from "../../types/Feedback";
import { feedback } from "../../data/feedback";

import Arrow from '../../assets/images/home/arrow.svg';

import './styles.css';

type Props = {
    slides: Feedback[];
}

function FeedbackSlider({ slides } : Props) {

    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    if(!Array.isArray(slides) || slides.length <= 0) return null;

    return(
        <>
            {feedback.map((slide, index) => (
                <div className={index === current ? "feedback-content slider--active" : "feedback-content"} key={index}>
                    <div className="feedback-img">
                        <img src={slide.imgSrc} alt={slide.people} />
                    </div>
                    <blockquote className="feedback-text">
                        <p>{slide.description}</p>
                        <p>{slide.people}</p>
                    </blockquote>
                    <div className="feedback-arrows">
                        <img src={Arrow} alt="Feedback anterior" className="arrow--left"  onClick={prevSlide} />
                        <img src={Arrow} alt="Próximo feedback" className="arrow--right" onClick={nextSlide} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default FeedbackSlider;