import Header from '../../components/Header';
import ButtonWhatsappChat from '../../components/ButtonWhatsappChat';
import Title from '../../components/Title';
import PhotoFilter from '../../components/PhotoFilter';
import Footer from '../../components/Footer';

function Photographs(){

    window.scrollTo({ top: 0, behavior: 'smooth' });

    return(
        <>
            <Header type="black" />
            <ButtonWhatsappChat />

            <main>
                <div className="container">
                    <div className="m-top110">
                        <Title text="Fotografias" />
                    </div>

                    <PhotoFilter />
                </div>
            </main>

            <Footer />
        </>
    )
}

export default Photographs;