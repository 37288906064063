import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Img1 from '../../assets/images/home/carousel-img1.jpg';
import Img2 from '../../assets/images/home/carousel-img2.jpg';
import Img3 from '../../assets/images/home/carousel-img3.jpg';
import Img4 from '../../assets/images/home/carousel-img4.jpg';
import Img5 from '../../assets/images/home/carousel-img5.jpg';

import './styles.css';

function HomeCarousel() {


    return(
        <Carousel autoPlay interval={3000} showThumbs={false} showIndicators={false} infiniteLoop={true}>
            <div>
                <img src={Img1} alt="" />
            </div>
            <div>
                <img src={Img2} alt="" />
            </div>
            <div>
                <img src={Img3} alt="" />
            </div>
            <div>
                <img src={Img4} alt="" />
            </div>
            <div>
                <img src={Img5} alt="" />
            </div>
        </Carousel>
    )
}

export default HomeCarousel;