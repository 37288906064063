import { useParams } from 'react-router-dom';
import { allPhotos } from '../../data/photos/allPhotos';

import Header from '../../components/Header';
import ButtonWhatsappChat from '../../components/ButtonWhatsappChat';
import Footer from '../../components/Footer';

import './styles.css';

function IndividualPhotos() {

    window.scrollTo({ top: 0, behavior: 'smooth' })

    const { fotoId } = useParams();
    const result = allPhotos.filter((photo) => photo.customUrl === fotoId);
    let parsed = {};

    result.forEach((item) => {
        for(let i in item) {
            parsed[i] = item[i];
        }
    });

    let lstPhotos = parsed.lstPhotos;

    return(
        <>
            <Header type="black" />
            <ButtonWhatsappChat />

            <main className="individual-photo">
                <div className="container">
                    <div className="main-photo">
                        {result.map((item, index) => (
                            <div key={index}>
                                <div className="pointer-none">
                                    <img src={item.mainPhoto} alt={item.name} />
                                </div>
                                <div>
                                    <p>{item.name}</p>
                                    {item.complementaryText !== '' ? <p>{item.type} | {item.complementaryText}</p> : <p>{item.type}</p>}
                                    <p>{item.location}</p>
                                    <p className="m-top10">{item.date}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="other-photos">
                        {lstPhotos.map((photo, index) => (
                            <div key={index} className="pointer-none">
                                <img src={photo} alt={`Foto ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <Footer />
        </>
    )
}

export default IndividualPhotos;