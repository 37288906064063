import Header from "../../components/Header";
import ButtonWhatsappChat from "../../components/ButtonWhatsappChat";
import Title from "../../components/Title";
import FilmFilter from "../../components/FilmFilter";
import Footer from "../../components/Footer";

function Films() {

    window.scrollTo({ top: 0, behavior: 'smooth' });

    return(
        <>
            <Header type="black" />
            <ButtonWhatsappChat />

            <main>
                <div className="container">
                    <div className="m-top110">
                        <Title text="Filmes" />
                    </div>

                    <FilmFilter />
                </div>
            </main>

            <Footer />
        </>
        
    )
}

export default Films;