import { NavLink } from 'react-router-dom';
import Illustration from '../../assets/images/not_found/not-found.svg';

import './styles.css';

function NotFound(){
    return(
        <main className="not-found">
            <div className="container">
                <div>
                    <h1>Ops, esta página não foi encontrada</h1>
                    <p>A página que você está procurando não existe ou está temporariamente indisponível.</p>
                    <div className="button-wrapper">
                        <NavLink to="/" className="btn">Voltar ao site</NavLink>
                    </div>
                </div>
                <div className="img-wrapper">
                    <img src={Illustration} alt="Não encontrado" />
                </div>
            </div>
        </main>
    )
}

export default NotFound;