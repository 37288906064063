import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { WPP_URL } from '../../utils/WhatsappLink';

import WhatsappLogo from '../../assets/images/whatsapp-logo.svg';
import CloseIcon from '../../assets/images/close-icon.svg';

import './styles.css';

function ButtonWhatsappChat(){
    
    window.onload = () => localStorage.setItem("showPopUp", "true");
    window.onbeforeunload = () => localStorage.removeItem("showPopUp");

    const [isPopupClosed, setIsPopupClosed] = useState(false);
    
    const closePopup = () => {
        setIsPopupClosed(true);
        localStorage.setItem("showPopUp", "false");
    }
    
    return (
        <div className="whatsapp-container">

            {!isPopupClosed && localStorage.getItem('showPopUp') !== 'false' &&
                <div className="whatsapp-popup">
                    <p>Olá, em que podemos ajudar? Sinta-se à vontade para entrar em contato pelo Whatsapp.</p>
                    <div>
                        <img src={CloseIcon} alt="Fechar pop-up" onClick={closePopup}></img>
                    </div>
                </div>
            }

            <div className="whatsapp-button">
                <NavLink to={WPP_URL} target='_blank'>
                    <img src={WhatsappLogo} alt="Entre em contato pelo Whatsapp" />
                </NavLink>
            </div>
        </div>
    )
}

export default ButtonWhatsappChat;