import { useState } from 'react';
import { Link } from 'react-router-dom';
import { PortfolioCardInfo } from '../../types/PortfolioCardInfo';

import './styles.css';

type Props = {
    film: PortfolioCardInfo;
}

function SecondaryCard({ film } : Props){

    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    const bgCardStyle = {
        background: isHover ? `linear-gradient(rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url('${film.thumbnail}') center center/cover no-repeat` : `url('${film.thumbnail}') center center/cover no-repeat`,
    }

    return(
        <article>
            <Link to={`/filmes/${film.customUrl}`} className="secondary-card" style={bgCardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="secondary-card__info">
                    <p className="secondary-card__name">{film.name}</p>
                    <p className="secondary-card__location">{film.location}</p>
                </div>
            </Link>
        </article>
    )
}

export default SecondaryCard;