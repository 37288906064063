import { Link, NavLink } from 'react-router-dom';

import InstagramIcon from '../../assets/images/instagram-icon.svg';
import FacebookIcon from '../../assets/images/facebook-icon.svg';
import EmailIcon from '../../assets/images/email-icon.svg';
import WhatsappIcon from '../../assets/images/whatsapp-icon.svg';
import { WPP_URL } from '../../utils/WhatsappLink';

import './styles.css';


function Footer(){
    return(
        <footer>
            <div className="container">
                <div className="footer-content">
                    <div className="footer-links">
                        <nav>
                            <ul>
                                <li>
                                    <Link to="/sobre" target="_self">Sobre</Link>
                                </li>
                                <li>
                                    <Link to="/fotos" target="_self">Fotografias</Link>
                                </li>
                                <li>
                                    <Link to="/filmes" target="_self">Filmes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="footer-social-media">
                            <p>Redes sociais</p>
                            <NavLink to="https://instagram.com/3rvideo" target="_blank">
                                <img src={InstagramIcon} alt="Instagram" />
                                Instagram
                            </NavLink>
                            <NavLink to="https://facebook.com/3rvideofoto" target="_blank">
                                <img src={FacebookIcon} alt="Facebook" />
                                <span className="m-left8">Facebook</span>
                            </NavLink>
                        </div>
                        <div className="footer-contacts">
                            <p>Contatos</p>
                            <Link to="mailto:3rvideofoto@gmail.com" target="_blank">
                                <img src={EmailIcon} alt="E-mail" />
                                3rvideofoto@gmail.com
                            </Link>
                            <Link to={WPP_URL} target="_blank">
                                <img src={WhatsappIcon} alt="Whatsapp" />
                                (21) 99547-0950
                            </Link>
                        </div>
                    </div>
                    
                </div>
                <div className="footer-copyright">
                    <span></span>
                    <p>&copy; 2024 3rvideofoto</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;