import { useState } from 'react';
import InputMask from 'react-input-mask';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

import { KEY, SERVICE, TEMPLATE } from '../../utils/EmailJS';
import { FormFields } from '../../types/FormFields';

import './styles.css';

function ContactForm(){
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        eventType: '',
        message: ''
    });

    const [emptyValue, setEmptyValue] = useState(false);

    const handleChange = (e : any) => {
        let auxProp = form;
        auxProp[e.target.name as keyof FormFields] = e.target.value;
        
        setForm({ ...auxProp });
    }

    const clearFields = () => {
        setForm({name: '', email: '', phone: '', eventType: '', message: ''})
    }

    const handleSubmit = (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let emptyValues = Object.values(form).some(obj => obj === '');
        setEmptyValue(emptyValues);

        const templateParams = {
            from_name: form.name,
            email: form.email,
            phone: form.phone,
            event_type: form.eventType,
            message: form.message
        }

        if(!emptyValues) {
            emailjs.send(SERVICE, TEMPLATE, templateParams, KEY).then((response) => {
                console.log('Status: ' +response.status, response.text);
                toast.success('Mensagem enviada com sucesso. Em breve entraremos em contato');
                clearFields();
            }, (error) => {
                console.log('Erro: ' ,error);
            })
        }
    }

    return(
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-name-email">
                <div>
                    <label htmlFor="name">Nome<span className="required-marker">&#42;</span></label>
                    <input type="text" name="name" className={emptyValue && form['name'].length === 0 ? 'error-input' : ''} value={form['name']} onChange={(e) => handleChange(e)} />
                    {emptyValue && form['name'].length === 0 ? <span className="error-text">Este campo é obrigatório</span> : ''}
                </div>
                <div>
                    <label htmlFor="email">E-mail<span className="required-marker">&#42;</span></label>
                    <input type="email" name="email" className={emptyValue && form['email'].length === 0 ? 'error-input' : ''} placeholder="exemplo@email.com" value={form['email']} onChange={(e) => handleChange(e)} />
                    {emptyValue && form['email'].length === 0 ? <span className="error-text">Este campo é obrigatório</span> : ''}
                </div>
            </div>
            <div className="form-phone-event">
                <div>
                    <label htmlFor="phone">Celular<span className="required-marker">&#42;</span></label>
                    <InputMask mask="(99) 99999-9999" name="phone" className={emptyValue && form['phone'].length === 0 ? 'error-input' : ''} value={form['phone']} onChange={(e) => handleChange(e)} />
                    {emptyValue && form['phone'].length === 0 ? <span className="error-text">Este campo é obrigatório</span> : ''}
                </div>
                <div>
                    <label htmlFor="eventType">Tipo do evento<span className="required-marker">&#42;</span></label>
                    <input type="text" name="eventType" className={emptyValue && form['eventType'].length === 0 ? 'error-input' : ''} placeholder="Casamento, 15 anos, infantil, etc" value={form['eventType']} onChange={(e) => handleChange(e)} />
                    {emptyValue && form['eventType'].length === 0 ? <span className="error-text">Este campo é obrigatório</span> : ''}
                </div>
            </div>
            <div className="form-message">
                <label htmlFor="message">Mensagem<span className="required-marker">&#42;</span></label>
                <textarea name="message" placeholder="Digite aqui a sua mensagem" className={emptyValue && form['message'].length === 0 ? 'error-input' : ''} rows={10} value={form['message']} onChange={(e) => handleChange(e)} ></textarea>
                {emptyValue && form['message'].length === 0 ? <span className="error-text">Este campo é obrigatório</span> : ''}
            </div>
            <div className="form-send-btn-wrapper">
                <button type="submit" className="btn">Enviar</button>
            </div>
        </form>
    )
}

export default ContactForm;